import { render, staticRenderFns } from "./passengerFlowDetail.vue?vue&type=template&id=018e6af7&scoped=true"
import script from "./passengerFlowDetail.vue?vue&type=script&lang=js"
export * from "./passengerFlowDetail.vue?vue&type=script&lang=js"
import style0 from "./passengerFlowDetail.vue?vue&type=style&index=0&id=018e6af7&prod&lang=scss&scoped=passengerFlowDetail"


/* normalize component */
import normalizer from "!../../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "018e6af7",
  null
  
)

export default component.exports