<template>
    <div id="passengerFlowDetail">
        <jy-query ref="form" :model="form">
            <jy-query-item prop="routeId" label="线路:">
                <!-- <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.routeName"></el-input>
                </div> -->
                <el-select v-model="form.routeId" placeholder="请选择线路">
                    <el-option v-for="item in routeOptions" :key="item.routeId" :label="item.name" :value="item.routeId">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item prop="vehicleNo" label="车牌号:">
                <el-input placeholder="请输入" v-model="form.vehicleNo"></el-input>
            </jy-query-item>
            <jy-query-item prop="dir" label="方向:">
                <el-select v-model="form.dir" placeholder="请选择">
                    <el-option v-for="item in fxoptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="查询日期:" prop="searchByDate">
                <el-date-picker v-model="form.searchByDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
                </el-date-picker>
            </jy-query-item>
            <jy-query-item prop="siteName" label="站点:">
                <el-select v-model="form.siteName" placeholder="请选择">
                    <el-option v-for="item in zdoptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item prop="time" label="选择时间:" :span="1.5">
                <el-time-picker is-range v-model="form.time" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" value-format="HH:mm" format="HH:mm">
                </el-time-picker>
            </jy-query-item>
            <!--<jy-query-item prop="endTime" label="结束时间:" class="controlWidth">
                <el-time-select placeholder="结束时间" v-model="form.endTime" :picker-options="{start: '00:00',step: '00:15',end: '24:00',minTime: form.startTime}">
                </el-time-select>
            </jy-query-item>-->
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck">查询</el-button>
                <el-button type="primary" @click="resetForm('form')">重置</el-button>
                <el-row>
                    <!-- <el-button type="primary">导出</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <jy-table :data="dataList">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column prop="routeName" label="线路"></jy-table-column>
            <jy-table-column prop="orgName" label="所属机构" min-width="180"></jy-table-column>
            <jy-table-column prop="vehicleNo" label="车牌号"></jy-table-column>
            <jy-table-column prop="dirLabel" label="方向"></jy-table-column>
            <jy-table-column prop="siteName" label="站点名称"></jy-table-column>
            <jy-table-column prop="time" label="记录时间"></jy-table-column>
            <jy-table-column prop="getOn" label="上车人数"></jy-table-column>
            <jy-table-column prop="getOff" label="下车人数"></jy-table-column>
            <jy-table-column prop="passengers" label="车上人数"></jy-table-column>
        </jy-table>
        <div class="onCarBox">车上人数总计：{{onCar}}</div>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
        </jy-pagination>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" url="/waybillOperate/getTree" title="选择线路组织" :defaultProps="props"></institutions-tree>
    </div>
</template>
<script>
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
export default {
    data() {
        return {
            props: {
                children: 'children',
                label: 'text',
            },
            routeId: '',
            form: {
                routeName: '',
                routeId: '',
                vehicleNo: '',
                dir: '',
                siteName: '',
                searchByDate: '',
                time: '',
            },
            pageSize: 10,
            total: 0,
            pageIndex: 1,

            // 车上人数总计
            onCar: '0',

            dataList: [
                {
                    routeName: '',
                    vehicleNo: '',
                    dirLabel: '',
                    siteName: '',
                    time: '',
                    getOn: '',
                    getOff: '',
                    passengers: '',
                },
            ],
            fxoptions: [
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: '0',
                    label: '上行',
                },
                {
                    value: '1',
                    label: '下行',
                },
            ],
            zdoptions: [],
            routeOptions:[],
        }
    },
    created() {
        this.getList()
        this.getDate()
    },
    activated() {
        this.getRouteList()
    },
    components: {
        institutionsTree,
    },
    watch: {
        'form.routeName': {
            handler(val) {
                if (val) {
                    this.routeId = this.form.routeId
                    this.getAllList()
                }
            },
            immediate: true,
        },
    },
    methods: {
        getRouteList(){
            let url = '/baseinforoute/queryCurrentAuthorityRoute'
            this.$http.post(url).then(({detail})=>{
                this.routeOptions = detail
            })
        },
        getAllList() {
            let url = '/baseinfosite/querySiteNameByRouteId'
            let o = {
                routeId: this.routeId,
            }
            let option = [
                {
                    label: '全部',
                    value: '',
                },
            ]
            this.$http.post(url, o).then(({ detail }) => {
                detail.forEach(item => {
                    option.push({
                        label: item.name,
                        value: item.name,
                    })
                })
                this.zdoptions = option
            })
        },
        getDate() {
            var date = new Date()
            var year = date.getFullYear()
            var month = date.getMonth() + 1
            var day = date.getDate()
            if (month < 10) {
                month = '0' + month
            }
            if (day < 10) {
                day = '0' + day
            }
            var nowDate = year + '-' + month + '-' + day
            this.form.searchByDate = nowDate
        },
        getList() {
            let option = {
                ...this.form,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            if (option.time) {
                option.beginTime = option.time[0]
                option.endTime = option.time[1]
            }
            let url = '/statpassengerflowdetail/queryPageDataList'
            console.log(option)
            this.$http.post(url, option).then(res => {
                if (res.retain == '1') {
                    this.dataList = []
                    this.$message({
                        message: res.error,
                        type: 'warning',
                    })
                } else {
                    this.dataList = res.detail.pageInfo.list
                    this.total = res.detail.pageInfo.total
                    this.onCar = res.detail.passengerSum
                }
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getList()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.form.time = ''
            this.form.routeId = ''
            this.form.beginTime = ''
            this.form.endTime = ''
            this.oncheck()
        },
        // 线路组织
        selectInstitutions() {
            this.$refs.institutions.init()
        },
        addInstitutions(data) {
            console.log(data)
            this.form.routeId = data.id
            this.form.routeName = data.text
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
    },
}
</script>
<style lang="scss" scoped="passengerFlowDetail">
#passengerFlowDetail {
    .onCarBox {
        padding-top: 10px;
        font-size: 12px;
    }
}
</style>
<style lang="scss">
</style>
